import React, { Component } from 'react'
import { View, ScrollView, Text } from 'react-native'
import DynamicRow from './component/DynamicRow'
import style from './style'
import contextMenuStyle from '../ContextMenu/styles'
import getPlatformType from '../../utils/getPlatformType'
import { DataTableProps } from './interface'

interface state {
    updatedHeaderData?: any
}

class DataTable extends Component<DataTableProps, state> {
    constructor(props) {
        super(props)
        this.state = {
            updatedHeaderData: [...this.props.headerData],
        }
    }

    renderRow = ({ item, index, length }: any) => {
        const {
            tableDataContainerStyle,
            tableDataTextStyle,
            renderItem,
            ContextMenu,
        } = this.props

        return (
            <DynamicRow
                rowData={item}
                length={length}
                index={index}
                headerData={this?.state?.updatedHeaderData}
                ContextMenu={ContextMenu}
                renderItem={renderItem}
                testID={`dataTableRow-${index}`}
                key={index}
            />
        )
    }

    renderRows = (tableData: Array<Object>) => {
        return tableData?.map((item, index) => {
            return this.renderRow({
                item,
                index,
                length: tableData.length,
            })
        })
    }

    renderRowsMobile = (tableData: Array<Object>) => {
        return (
            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={style.mobileTableContent}
            >
                <ScrollView>
                    {this.renderRows(tableData)}
                </ScrollView>
            </ScrollView>
        )
    }

    render() {
        const {
            tableData,
            tableTitle,
            headerDataContainerStyle,
            headerDataTextStyle,
            titleStyle,
            titleContainerStyle,
            containerStyle,
            ContextMenu,
        } = this.props
        const { updatedHeaderData } = this.state

        const tableRows =
            getPlatformType() == 'phone'
                ? this.renderRowsMobile(tableData)
                : this.renderRows(tableData)

        return (
            <View style={style.scrollContainer}>
                <View style={[style.container]}>
                    <View style={[style.tableRow]}>
                        <View style={[style.header]}>
                            {updatedHeaderData?.map(
                                (headerValue: string, index: number) => (
                                    /* TODO: need to support headerDataTextStyle - however, consider diverging styles amongst rows */
                                    <Text
                                        style={[style.headerText]}
                                        key={index}
                                    >
                                        {headerValue}
                                    </Text>
                                ),
                            )}
                            {ContextMenu && (
                            <View
                                style={[
                                    contextMenuStyle.container,
                                    style.autoWidthCell,
                                    headerDataContainerStyle,
                                ]}
                            ></View>
                        )}
                        </View>
                    </View>
                    {tableTitle && (
                        <View style={[style.textStyle, titleContainerStyle]}>
                            <Text style={[style.titleStyle, titleStyle]}>
                                {tableTitle}
                            </Text>
                        </View>
                    )}
                    {tableRows}
                </View>
            </View>
        )
    }
}

export default DataTable
